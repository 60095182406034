export default defineNuxtRouteMiddleware(async (to) => {
  const auth = useAuth()
  const { env } = useRuntimeConfig().public

  /**
   * Pathfinder / Nuxt Studio preview mode
   */
  if ((to.path.includes('pathfinder') && to.query.preview) || useCookie('previewToken').value) {
    return
  }

  if (to.path.includes('pathfinder') && env === 'staging') {
    return
  }

  const { clarity } = useScriptClarity({
    id: 'mpy5c6k7xi',
    scriptOptions: {
      trigger: env === 'production' ? 'onNuxtReady' : 'manual',
    },
  })
  const { hj } = useScriptHotjar({
    id: 5015054,
    scriptOptions: {
      trigger: env === 'production' ? 'onNuxtReady' : 'manual',
    },
  })
  if (!auth.loggedIn) {
    auth.log('middleware', 'not logged in')
    auth.setRedirectTo(to.path)
    // we either try to hydrate the session from a payload within local storage and async the auth
    // OR we sync wait for the auth check if no payload exists
    await auth.check({
      from: 'middleware',
      swr: true,
      redirect: true,
    }).then((res) => {
      if (res && auth.me) {
        clarity('identify', auth.me.id, undefined, to.path, auth.me.firstName)
        clarity('set', 'user_id', auth.me.id)
        clarity('set', 'user_email', auth.me.email)
        clarity('set', 'user_name', `${auth.me.firstName} ${auth.me.lastName}`)
        hj('identify', auth.me.id, { email: auth.me.email, name: `${auth.me.firstName} ${auth.me.lastName}` })
      }
    })
  }
})
